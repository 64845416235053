<template>
  <b-card no-body class="p-2">
    <h3 class="mb-2">
      {{
        language.idiomaId == 0
          ? $t("Routes.LanguageAdd")
          : $t("Routes.LanguageEdit")
      }}
    </h3>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- name work area field -->
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.language.columns.name')"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.language.columns.name')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-input v-model="language.nombre" />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.language.columns.abbreviation')"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.language.columns.abbreviation')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-input v-model="language.abreviatura" />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Description Field -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="Description"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.language.columns.description')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-textarea v-model="language.descripcion" />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="float-right mt-2 mb-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            <b-spinner v-if="addingLanguage" small class="mr-1" />
            {{ language.idiomaId == 0 ? $t("Lists.Add") : $t("Lists.Edit") }}
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,

  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import humanResources from '@/services/humanresources.service'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import router from '@/router'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormInvalidFeedback,
    BFormGroup,
    BFormTextarea,
    BButton,

    ValidationProvider,
    ValidationObserver,

  },
  setup() {
    const addingLanguage = ref(false)
    const language = ref({
      idiomaId: 0,
      nombre: '',
      abreviatura: '',
      descripcion: '',
      archivado: false,
    })

    const blankLanguage = {
      idiomaId: 0,
      nombre: '',
      abreviatura: '',
      descripcion: '',
      archivado: false,
    }

    const {
      fetchLanguage,
      createLanguage,
      updateLanguage,
    } = humanResources()

    const resetLanguage = () => {
      language.value = JSON.parse(JSON.stringify(blankLanguage))
    }

    if (router.currentRoute.params.idiomaId) {
      fetchLanguage(router.currentRoute.params.idiomaId, data => {
        language.value = data
      })
    } else {
      language.value = JSON.parse(JSON.stringify(blankLanguage))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetLanguage)

    const onSubmit = () => {
      addingLanguage.value = true
      if (language.value.idiomaId === 0) {
        createLanguage(language.value, () => {
          addingLanguage.value = false
          router.push({ name: 'apps-human-resources-language-list' })
        })
      } else {
        updateLanguage(language.value.idiomaId, language.value, () => {
          addingLanguage.value = false
          router.push({ name: 'apps-human-resources-language-list' })
        })
      }
    }

    return {
      language,
      addingLanguage,
      required,
      getValidationState,
      refFormObserver,
      resetForm,
      onSubmit,

    }
  },
}
</script>
